@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'ProximaNova-Reg', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:39px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'ProximaNova-Reg', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:24px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'ProximaNova-Reg', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'ProximaNova-Reg', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'ProximaNova-Reg', sans-serif;
}
h6 {
	font-size:20px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'ProximaNova-Reg', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'ProximaNova-Reg', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #000;
	font-family: 'ProximaNova-Reg', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#333;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #000 !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'ProximaNova-Reg', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #000;
	border-width: 6px 5px 0;
    opacity:1;
    height:7px !important;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 15px;
	left: 0;
	font-size: 22px;
    float:right;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #000;
	font-size: 16px;
	font-family: 'ProximaNova-Reg', sans-serif;
}
.links > li > a:hover, .userSwitch a:hover {
	background-color: #00A7E0;
	color: #000 !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #000;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #fff !important;
	padding: 0;
	border-radius: 10px;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #00A7E0 !important;
}
#mini-cart .dropdown-menu p, #mini-cart .dropdown-menu a {
     line-height: initial;
 }
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 39px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #000;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #fff;
	border-radius: 10px;
	padding: 0;
    
}
button, .button, .button a, button a {
	font-family: 'ProximaNova-Reg', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
    border: 1px solid #00A7E0;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #00A7E0 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #00A7E0;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #00A7E0;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
    display:none;
}


/************************** MICS **************************/
.footer-top .section {
     width:100%;
     padding-top:0;
}
.collapsible ul.bullet li {
     background:none;
     margin-bottom:5px;
}
#footer .block-title.heading {
     letter-spacing:0;
     font-size:18px;
     margin-bottom:0;
}
#footer .logo-col img {
     max-width:200px;
}
.collapsible ul.bullet li > a, .copyright-txt{
     font-size:14px;
}
.collapsible ul.bullet li:hover > a {
     border-bottom:1px solid #000;
}
#footer .social-icons i {
     font-size:24px;
}
#footer .social-icons {
     display:grid;
     grid-template-columns:10% 10% 10%;
     grid-gap:5%;
     justify-content:end;
}
#footer .social-icons > a {
     padding-top:8px;
     margin-right:2%;
}
#scroll-to-top:hover {
     background-color:#04335B;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .footer-links-col {
         width:48%;
    }
     .social-icon-column {
         width:14.66%;
    }
     #footer .social-icons {
         grid-template-columns:auto auto auto;
         grid-gap:5%;
         justify-content:center;
    }
}
@media only screen and (max-width:767px) {
     .logo-col p {
         text-align:center;
    }
     .collapsible .opener:hover {
         background-color:#04335B !important;
    }
     .social-icon-column {
         margin:5% 0;
    }
     #footer .social-icons {
         justify-content:center;
    }
}


#header {
     border-bottom:1px solid #ddd;
}
.header-top-container {
     background-color:#00A7E0;
     box-shadow:none;
}
.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
}
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a, .top-header-container .module-header-multi p{
     height:48px;
     line-height:48px;
}
.top-header-container .module-header-multi p{
     margin-bottom:0 !important;
}
.top-header-container .item-welcome-msg {
     margin-left:0 !important;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .item.item-user-links ul.links > li:last-child a{
     padding-right:0;
}
.top-header-container .links>li>a, .userSwitch a, .welcome-msg {
     font-size:16px;
}
.desktop-top-header {
     padding:10px 0;
}
.desktop-top-header .header-top-container, .mobile-header .header-top-container {
     background-color:#fff;
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
.desktop-top-header .grid-container {
     display:flex;
}
.desktop-top-header .logo-column{
     margin:auto 0;
}
.desktop-top-header .category-column, .desktop-top-header .search-column {
     margin:auto;
}
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
.cart-total {
     display:inline-block;
     border-radius:50%;
     color:#00A7E0 !important;
     background-color:#fff;
     font-weight:700;
     font-size:12px !important;
     height:20px !important;
     width:20px;
     line-height:20px !important;
     text-align:center;
     position:relative;
     top:-8px;
}
/*mini-cart*/
#mini-cart .feature-icon-hover > a{
     display:inline-flex;
}
#mini-cart .feature-icon-hover > a > span {
     padding:0 !important;
}
#mini-cart .feature-icon-hover > a > span, #mini-cart .feature-icon-hover > a > .empty {
     order:1;
}
#mini-cart .feature-icon-hover > a > .empty span.price {
     margin-top:0;
}
.category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}
@media only screen and (min-width:1400px) {
     .category-column .nav-regular li.level0>a>span {
         font-size:20px;
    }
}
.category-column .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
.category-column ul#nav {
     display:flex;
     justify-content:center;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
.desktop-top-header .module-search .form-search input::placeholder {
     color:#000 !important;
}
.desktop-top-header .module-search .form-search .button-search .fa {
     color:#000 !important;
     padding:7px 10px !important;
}
.desktop-top-header .module-search .form-search .input-text {
     font-weight:400;
     font-size:16px;
     box-shadow:0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
     height:30px;
     line-height:30px;
}
#inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:900px;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:767px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     #inline-search-results {
         width:500px;
         right:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#000;
}
@media only screen and (max-width:959px) {
     .desktop-top-header, .top-header-container, .top-header-container .grid-column-wrapper, .top-header-container .module-header-multi, .top-header-container .item.item-min-cart{
         overflow:unset;
    }
     .desktop-top-header .logo-column, .desktop-top-header .search-column {
         width:50%;
    }
     .fa.fa-bars.userCode {
         vertical-align:baseline;
    }
     .desktop-top-header {
         padding:0 !important;
    }
}
@media only screen and (max-width:767px) {
     .top-header-container, .mobile-header {
         margin-bottom:5px !important;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         overflow:hidden !important;
    }
     .mobile-header .grid-full.module-header-multi > div{
         margin:auto;
    }
     .mobile-header .fa.fa-bars, .mobile-header .fa.fa-vcard:after, .mobile-header .fa.fa-shopping-cart, .mobile-header .item-search .fa.fa-search, .mobile-header .fa-times {
         color:#000 !important;
         font-size:20px !important;
    }
     .mobile-header .header .logo-wrapper {
         text-align:left !important;
         margin:0 !important;
    }
     .mobile-header .item-search {
         margin-left:0 !important;
    }
     .mobile-header .item-search .fa-search{
         padding-right:0 !important;
    }
     .mobile-header .fa-shopping-cart {
         left:0 !important;
         top:0 !important;
    }
     .mobile-header #mini-cart .empty {
         display:none;
    }
     .mobile-header .logo img {
         max-width:120px;
    }
     .cart-total {
         display:inline-block;
         border-radius:50%;
         color:#fff !important;
         background-color:#20b8eb;
         font-weight:700;
         font-size:12px !important;
         height:20px;
         width:20px;
         line-height:20px;
         text-align:center;
         position:relative;
         top:-8px;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:16px !important;
    }
     /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#000;
         padding:10px;
         font-style:normal;
    }
}
@media only screen and (min-width:960px) and (max-width:1199px) {
     .desktop-top-header .logo img {
         max-width:200px;
    }
     .category-column ul#nav > li > a {
         padding-left:0;
    }
     .category-column ul#nav > li > a > span{
         font-size:14px;
    }
}
/**/
.account-view .sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:70px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     margin:0 auto;
     list-style:none;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover {
     background-color:#00A7E0;
     border-radius:5px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover a{
     color:#fff;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     font-weight:bold;
     padding:0 12px;
     line-height:40px;
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
.account-view .user-grid-content .page-title {
     overflow:hidden;
}
.account-view .xm-grid-account .grid-container-wrapper {
     overflow-x:hidden;
}
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
#order-table, #transhistory-table, #address-book-table, #shipment-table{
     margin-top:20px;
}
.module-order-details-actions p.back-link .fa-angle-double-left:before {
     content:"";
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:250px;
    }
     .module-order-details-misc > div {
         padding-left:10px;
    }
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
@media only screen and (max-width:959px) {
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable span{
         font-size:11px !important;
    }
     .module-account-transhistory-content-placeholder {
         margin-top:20px;
    }
     .module-order-details-listing, .ord-comment {
         overflow-x:hidden;
    }
}
#openinvc-href span span{
     padding:10px;
}
.page-title.module-trans-details-status h1 span {
     font-size:32px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie, #openinvc-href span span, a.button span span {
     padding:10px;
     background-color:#fff;
     border-radius:10px;
     cursor:pointer;
     color:#00A7E0;
     border:1px solid #00A7E0;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover, a.button:hover span span {
     background-color:#00A7E0 ;
     color:#fff !important;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
}
.login-title h1 {
     text-align:center;
}
@media only screen and (min-width:960px) {
     .login-section .grid-container {
         display:flex;
         justify-content:center 
    }
}
@media only screen and (min-width:1399px) {
     .login-section .grid-column-wrapper {
         width:32%;
    }
}
@media only screen and (max-width:767px) {
     .login-section .grid-column-wrapper {
         margin-top:5%;
    }
}
/*Pay Open Invoice*/
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc #form_filter_user_data {
         display:flex;
         margin-bottom:20px;
    }
     .po-invc #form_filter_user_data > * {
         margin:auto;
    }
     .po-invc .filter_order_search_label {
         line-height:12px;
    }
     .po-invc .my-account .pager p.amount strong, .po-invc .filter_order_search_label, .po-invc .my-account > p, .po-invc button.button span, .po-invc .pages strong, .po-invc .pages li, .po-invc .pages li a{
         font-size:12px !important;
         font-weight:400 !important;
    }
     .po-invc .pages ol {
         padding-left:0;
         margin-top:5px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
}

.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
#header {
     position:sticky;
     top:0;
     z-index:50;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}
/**/
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
}
.product-listing {
     margin-top:0 !important;
}
.product-listing .add-cart-button span span{
     padding:0px !important;
}
.product-listing .page-title h1 {
     padding-top:0;
     padding-bottom:20px;
     color:#00A7E0;
}
.product-listing .item-code-label {
     display:none;
}
.product-search-container, .breadcrumbs_col .module-category-misc > div, #breadcrumbs li.home span, .breadcrumbs_col .nested-container {
     margin-left:0 !important;
}
#breadcrumbs li.last-child > span {
     vertical-align:super;
}
.breadcrumb-separator {
     background:none !important;
     text-indent:0 !important;
     overflow:unset !important;
     margin:0 !important;
     font-size:16px !important;
     width:auto !important;
}
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager .pages li {
     height:36px;
     line-height:36px;
}
.pager li a, .pager strong{
     font-size:14px;
}
.pager .pages li a {
     background-color:#00A7E0;
     color:#000;
}
.pager .pages li.current {
     background-color:#fff;
     color:#000;
}
.pager .pages li a:hover {
     background-color:#fff;
     color:#000 !important;
}
.toolbar-section {
     border-bottom:1px solid #ddd;
     padding-bottom:1em;
}
.toolbar-section .module-category-misc > div:nth-child(1) {
     margin-top:5px;
}
.toolbar-section .module-category-misc {
     display:flex;
     justify-content:space-between;
     flex-wrap:wrap;
     gap:10px;
}
 .toolbar-section .module-category-misc > div {
     margin:auto 0;
}
.sorter .view-mode span.grid, .sorter .view-mode span.line {
     background-color:#00A7E0 ;
}
.sorter .view-mode .line.active {
     background-position:-60px -237px;
}
.sorter .view-mode .grid.active {
     background-position:0px -237px;
}
.sorter .view-mode label {
     display:none;
}
.category-products-grid .item {
     border:none;
     margin-bottom:50px;
     padding-bottom:85px !important;
}
.item .module-category-product-listing h2.product-name {
     margin-bottom:15px;
}
.item .module-category-product-listing h2.product-name a {
     color:#000;
}
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:60px;
}
.item .module-category-product-listing .price-box {
     margin-top:15px;
}
.module-category-product-listing__price .price-box span{
     font-weight:600;
}
@media only screen and (min-width:768px) {
     .item .module-category-product-listing .add-cart-wrapper {
         display:flex;
         justify-content:center;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
         margin:auto 10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > button{
         margin:auto;
         margin-left:10px;
    }
}
.module-category-product-listing__actionButtons .add-cart-wrapper input.qty {
     height:45px;
     border:1px solid #00A7E0 ;
     border-radius:10px;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12, .breadcrumbs_col .grid-column-wrapper {
         overflow-y:hidden;
    }
     .mobile-filters {
         vertical-align:super !important;
    }
     .mobile-filters .fa-sliders:before {
         font-size:22px;
         font-weight:700;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
     .products-grid .item button.button span span {
         padding:0px !important;
         height:40px !important;
         line-height:40px;
    }
     .pager .pages li {
         display:inherit;
    }
}
.category-products-listing .products-line__labels{
     margin:1em 0;
}
.products-line .module-category-product-listing .module-category-product-listing__actionButtons .qty-wrapper input.qty {
     width:3em!important;
}
@media only screen and (min-width:768px) and (max-width:1199px) {
     .module-category-product-listing .products-line__labels h2.product-name a, .module-category-product-listing .products-line__labels .item-code a, .module-category-product-listing .products-line__labels .price-box span{
         font-size:16px;
    }
}
@media only screen and (min-width:960px) {
     .module-category-product-listing .products-line__labels ul {
         display:flex;
    }
     .module-category-product-listing .products-line__labels ul > li{
         margin:auto;
    }
     .module-category-product-listing .products-line__labels .price-box p {
         margin-bottom:2px !important;
    }
     .module-category-product-listing .products-line__labels .btn-cart{
         margin-top:0 !important;
    }
     .module-category-product-listing .products-line__labels .add-cart-wrapper{
         float:right;
    }
}
@media only screen and (max-width:767px) {
     .module-category-product-listing .products-line__labels .price-box {
         margin-top:10px;
    }
     .module-category-product-listing .products-line__labels .price-box p {
         margin-bottom:2px !important;
    }
     .module-category-product-listing .products-line__labels .btn-cart{
         margin-top:0 !important;
    }
}
@media only screen and (max-width:767px) {
     .quickviewbutton {
         display:none;
    }
}
.item .quickviewbutton {
     border-radius:5px;
     background-color:transparent!important;
}
.quickviewbutton span {
     font-size:16px !important;
     background-color:#00A7E0 !important;
     color:#fff !important;
}
.quickviewbutton span span {
     height:35px !important;
     line-height:35px !important;
     padding:0 14px !important;
}
#quick-view-modal .modal-footer {
     border-top:none;
}
#quick-view-modal button.button span {
     border-radius:10px;
}
.quick-view-modal-dialog .product-view .btn-cart span span {
     padding:0 20px !important;
     max-width:100px;
}
.quick-view-modal-dialog .button.pull-right {
     color:#000;
}
.quick-view-modal-dialog #add-to-cart-block {
     display:flex;
     float:none !important;
     justify-content:space-between;
}
.quick-view-modal-dialog #add-to-cart-block > * {
     margin:auto 0;
}
/**/
#shopping-cart-table h2.product-name > li::marker {
     font-size:0.5em;
}
body.cart .data-table:not(.cart-table) .col-img img {
     width:60%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     body.cart .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
.cart-action-buttons button {
     margin:20px 10px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .cart-action-col {
         overflow:hidden;
    }
}
#shopping-cart-table h2.product-name .item-code {
     display:none;
}
@media only screen and (min-width:1200px){
     .cart-summary-col {
         width:30%;
    }
     .update-empty-cart {
         width:66%;
    }
}
